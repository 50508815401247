import { useEffect, useState, useRef } from "react";
import * as d3 from "d3";
import "./App.css";

const colors = {
  "New Component": "pink",
  Investigation: "#FF9292",
  UX: "#700B97",
  Accessibility: "#664E88 ",
  Regression: "#E02401",
  Defect: "#F78812",
  Browser: "#FFE699",
  Refactor: "#113CFC",
  Enhancement: "#1597E5",
  "Feature Refactor": "#61B15A",
  "Feature Defect": "#ADCE74",
  Duplicate: "grey",
  Misc: "black",
};

function App() {
  const svgRef = useRef();
  const legendRef = useRef();
  const margin = { top: 10, right: 30, bottom: 30, left: 60 },
    width = 1500 - margin.left - margin.right,
    height = 600 - margin.top - margin.bottom;
  const size = 5;

  const [displayData, setDisplayData] = useState({});

  useEffect(() => {
    console.log("constructing");
    let legendEl = d3.select(legendRef.current);
    legendEl.selectAll("*").remove();
    Object.keys(colors).forEach((legendTitle, index) => {
      legendEl
        .append("circle")
        .attr("cx", 20)
        .attr("cy", 30 + index * 20)
        .attr("r", 6)
        .style("fill", colors[legendTitle]);
      legendEl
        .append("text")
        .attr("x", 40)
        .attr("y", 30 + index * 20)
        .text(legendTitle)
        .style("font-size", "15px")
        .attr("alignment-baseline", "middle");
    });

    const ranges = [];
    let svgEl = d3.select(svgRef.current);
    svgEl.selectAll("*").remove();
    svgEl = svgEl
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const minDate = new Date(2021, 1, 1);
    const maxDate = new Date(2021, 9, 20);

    var x = d3.scaleTime().domain([minDate, maxDate]).range([0, width]);
    svgEl
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add Y axis
    var y = d3.scaleLinear().domain([0, 40]).range([height, 0]);
    svgEl.append("g").call(d3.axisLeft(y));

    d3.csv("/component-analysis.csv").then((input) =>
      input.forEach((data) => {
        const createdDate = new Date(data.Created);
        let completedDate =
          data.Finished &&
          data.Finished !== "todo" &&
          data.Finished !== "refinement"
            ? new Date(data.Finished)
            : null;
        if (data.Finished === "In prog" || data.Finished === "refinement")
          completedDate = maxDate;
        if (data.Finished === "N/A" || data.Finished === "todo")
          completedDate = createdDate;
        if (data["First Dev Progress"].includes("??")) completedDate = maxDate;

        const rangeIndex = ranges.findIndex(
          (rangeRow) =>
            !rangeRow.find((rangeData) =>
              dateRangeOverlaps(
                rangeData.createdDate,
                rangeData.completedDate,
                createdDate,
                completedDate
              )
            )
        );

        let color = "black";
        Object.keys(colors).forEach((type) => {
          if (data.Category.includes(type)) {
            color = colors[type];
          }
        });

        let row;
        if (rangeIndex === -1) {
          row = ranges.length + 1;
          ranges.push([{ ...data, createdDate, completedDate, row }]);
        } else {
          row = rangeIndex + 1;
          ranges[rangeIndex].push({ ...data, createdDate, completedDate, row });
        }

        const dataEl = svgEl.append("g").on("click", () => {
          console.log(data);
          setDisplayData(data);
        });

        dataEl
          .append("line")
          .attr("x1", x(createdDate))
          .attr("y1", y(row))
          .attr("x2", x(completedDate))
          .attr("y2", y(row))
          .attr("stroke-width", 1)
          .attr("stroke", "black");

        if (data.Finished.includes("todo")) {
          dataEl
            .append("g")
            .append("circle")
            .attr("fill", "black")
            .attr("cx", function (d) {
              return x(createdDate);
            })
            .attr("cy", function (d) {
              return y(row);
            })
            .attr("r", size * 1.5);
        }

        dataEl
          .append("g")
          .append("circle")
          .attr("fill", color)
          .attr("cx", function (d) {
            return x(createdDate);
          })
          .attr("cy", function (d) {
            return y(row);
          })
          .attr("r", size);

        const firstProgString = data["First Dev Progress"];
        const firstProgressDate =
          firstProgString !== "N/A" && firstProgString !== ""
            ? new Date(data["First Dev Progress"])
            : null;

        dataEl
          .append("line")
          .attr("x1", x(firstProgressDate ? firstProgressDate : createdDate))
          .attr("y1", y(row))
          .attr("x2", x(completedDate))
          .attr("y2", y(row))
          .attr("stroke-width", size * 2)
          .attr("stroke", color);
      })
    );
  }, [height, margin.left, margin.right, margin.top, width]);

  const dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    if (a_start === b_end || a_end === b_start) return true; // a and b are the same
    return false;
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Component Analysis: Currency Input</h1>

        <div>
          <svg
            viewBox={`0 0 ${width + margin.left + margin.right} ${
              height + margin.top + margin.bottom
            }`}
            ref={svgRef}
          ></svg>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>Legend</span>
            <svg ref={legendRef} width={300} height={300}></svg>
            <span>Outline indicates TODO</span>
          </div>
          <div style={{ textAlign: "left", marginLeft: "30px" }}>
            <table>
              {Object.keys(displayData).map((dat) => (
                <tr>
                  <td>{dat}</td>
                  <td style={{ whiteSpace: "pre-line" }}>{displayData[dat]}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
